import { useState, React, useEffect } from "react";
import "../../assets/css/chat.css";
import BackIcon from "../../assets/images/left.svg";
import CallBlack from "../../assets/images/call-2.svg";
import Button from "react-bootstrap/Button";
import Person from "../../assets/images/onlineUser.png";
import Clip from "../../assets/images/clip.svg";
import Picture from "../../assets/images/picture.svg";
import Sent from "../../assets/images/sent.svg";
import SeacrchIcon from "../../assets/images/search.svg";
import Spinner from "react-bootstrap/Spinner";
import io from "socket.io-client";

import Auth from "../../libs/auth";
import { get, getAuthConfig, getAuthConfigImage, post } from "../../libs/http-hydrate";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import { useRef } from "react";

const Chat = () => {
  const location = useLocation();
  const [key, setKey] = useState("patient");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(location?.state?.patient);
  const [chatList, setChatList] = useState({
    page: 1,
    chats: [],
    has_more: false,
  });
  const [image, setImage] = useState(null); // State for selected image
  const [loadingUsers, setLoadingUsers] = useState(true); // Add loading state for users
  const [loadingChats, setLoadingChats] = useState(false); // Add loading state for chats
  const user = Auth.getCurrentUser();
  const socket = io("https://devapi.recureme.com"); // Update with your server address
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [sendingMessage, setSendingMessage] = useState(false); // New state for sending loader
  const messagesEndRef = useRef(null); // Create a reference for the end of chat messages
  const [loadingPreviousMessages, setLoadingPreviousMessages] = useState(false); // Add loading state for previous messages
  const chatContainerRef = useRef(null); // Reference for the chat container

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };
  useEffect(() => {
    fetchUserList(currentPage);
  }, [searchTerm, currentPage]);

  useEffect(() => {
    const userId = user?._id; // Replace with the actual user ID
    socket.emit("join", userId);

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("message", (message) => {
      console.log("messageReceived", message);
      if (message?.sender === selectedUser?._id) {
        setChatList((prevState) => ({
          ...prevState,
          chats: [...prevState.chats, message],
        }));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser?._id) {
      getChatList(1, selectedUser?._id);
    }
  }, [selectedUser]);
  useEffect(() => {
    if (loadingPreviousMessages === false) {
      scrollToBottom();
    }
  }, [chatList]);

  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  async function fetchUserList(page) {
    setLoadingUsers(true); // Set loading to true before fetching users
    try {
      const data = await get(
        `/chat/getUserList?page=${page}&searchTerm=${searchTerm}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        console.log(data, "data");
        setUsers(data?.data?.users);
        setTotalPages(data?.data?.totalPages);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoadingUsers(false); // Set loading to false after fetching users
    }
  }

  async function getChatList(page, otherUserId) {
    setLoadingChats(true); // Set loading to true before fetching chats
    try {
      const data = await get(
        `/chat/getMessages?page=${page}&otherUserId=${otherUserId}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        console.log(data, "data");

        // Combine old and new messages and sort them by date
        const newChats =
          page === 1
            ? data.data.messages
            : [...data.data.messages, ...chatList.chats];
        newChats.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setChatList((prevState) => ({
          page,
          chats: newChats,
          has_more: data.data.has_more,
        }));
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoadingChats(false); // Set loading to false after fetching chats
    }
  }

  const loadMoreMessages = async () => {
    if (chatList.has_more && !loadingPreviousMessages) {
      setLoadingPreviousMessages(true);

      // Store current scroll height and top offset
      const currentScrollHeight = chatContainerRef.current?.scrollHeight;
      const currentScrollTop = chatContainerRef.current?.scrollTop;

      await getChatList(chatList.page + 1, selectedUser?._id); // Load more messages

      // Calculate the new scroll position by adjusting for the new messages' height
      if (chatContainerRef.current) {
        const newScrollHeight = chatContainerRef.current.scrollHeight;
        chatContainerRef.current.scrollTop = newScrollHeight - currentScrollHeight + currentScrollTop;
      }

      setLoadingPreviousMessages(false);
    }
  };
  async function sendMessage() {
    setSendingMessage(true); // Start the sending loader

    const payload = new FormData();
    payload.append("senderId", user?._id);
    payload.append("senderModel", "Physiotherapist");
    payload.append("receiverId", selectedUser?._id);
    payload.append(
      "receiverModel",
      location?.state?.patient ? "Patient" : "Physiotherapist"
    );
    payload.append("message", message);
    if (image) {
      payload.append("chat_image", image); // Append image to payload if exists
    }
  
    try {
      
     
      const data = await post(`/chat/sendMessage`, payload, getAuthConfigImage());

      if (data?.status === 200) {

        const payload1 = {
          senderId: user?._id,
          senderModel: "Physiotherapist", // or "Physiotherapist" based on your context
          receiverId: selectedUser?._id,
          receiverModel: "Patient", // or "Patient" based on the target
          message: message,
          fileUrl: data.data.message[0]?.fileUrl,
          created_at: data.data.message[0].created_at
        };
      
        socket.emit("sendMessage", payload1); // Emit the event to the server
        setChatList((prevState) => ({
          ...prevState,
          chats: [...prevState.chats, data.data.message[0]],
        }));
        setMessage(""); // Clear the message input after sending
        setImage(null); // Reset image after sending
        scrollToBottom();

      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSendingMessage(false); // Stop the sending loader
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log(selectedUser, "selectedUser");
  return (
    <>
      <div className="chat-page">
        <div className="header-part">
          <div className="back-button">
            <Button className="back">
              <img src={BackIcon} alt="back" />
            </Button>
          </div>
          {selectedUser && (
            <div className="name-call">
              <div className="profile">
                <div className="image">
                  <img
                    src={
                      selectedUser?.profile_image
                        ? selectedUser?.profile_image
                        : selectedUser?.gender === "female"
                        ? PatientFemale
                        : selectedUser?.gender === "male"
                        ? PatientMale
                        : PatientCommon
                    }
                    alt="Person"
                    style={{borderRadius:"25px"}}
                    height={50}
                    width={50}
                  />
                </div>
                <div className="user-name ms-3">
                  <p>{selectedUser?.name}</p>
                </div>
              </div>
              {/* <Button className="call">
                <img src={CallBlack} alt="back" />
              </Button> */}
            </div>
          )}
        </div>
        <div className="main-chat-part" 
       // ref={chatContainerRef}
        >
          <div className="chat-side-part">
            <div className="chat-person">
              <div className="tab-part-data exercise-tab-part mt-0">
                <div className="tab-ans-part">
                  <div className="search-box">
                    <input
                      className="searchInput"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                    <div className="searchIcon">
                      <img src={SeacrchIcon} alt="icon" />
                    </div>
                  </div>
                  <div className="chatting-person">
                    {loadingUsers ? (
                      <div className="text-center">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {users?.length <= 0 && (
                          <div className="text-center">No User found</div>
                        )}
                        {users?.length > 0 &&
                          users?.map((user, index) => (
                            <div
                              key={index}
                              className="chat-card"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                setSelectedUser(user);
                              }}
                            >
                              <div className="image-blovk rounded">
                                <img
                                  src={
                                    user?.profile_image
                                      ? user?.profile_image
                                      : Person
                                  }
                                  alt="user"
                                  style={{ borderRadius: "25px" }}
                                  height={50}
                                  width={50}
                                />
                              </div>
                              <div className="text-block">
                                <div className="left">
                                  <p>{user?.name}</p>
                                  <small>{user?.lastMessage}</small>
                                </div>
                                {/* <div className='right'>
                                <p className='time'>9:11</p>
                              </div> */}
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="messages-part">
            {loadingChats ? (
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            ) : selectedUser ? (
              <>
                <div className="chatting">
                  {chatList?.has_more && (
                    <div className="text-center">
                      <button
                        onClick={loadMoreMessages}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 20px",
                          backgroundColor: "#FF6036", // Set to the specified orange shade
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "14px",
                          border: "none",
                          borderRadius: "25px",
                          cursor: "pointer",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          marginBottom: "10px",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#e5562e")
                        } // Slightly darker orange on hover
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#FF6036")
                        } // Reset to original color on leave
                      >
                        {loadingChats ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ marginRight: "8px" }}
                          />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    </div>
                  )}
                  {chatList?.chats.map((chat, index) => (
                    <div
                      key={index}
                      className={`messages w-75 ${
                        chat?.sender === user?._id ? "sender" : "reciver"
                      }`}
                    >
                      <div className="message-card">
                      {chat?.fileUrl && (
                          <img
                            src={chat.fileUrl}
                            alt="attachment"
                            style={{ maxWidth: "100%", borderRadius: "8px" }}
                          />
                        )}
                        <p>{chat?.message}</p>
                      </div>
                      <p className="timer">
                        {moment(chat.created_at).format("hh:mm A")}
                      </p>
                    </div>
                  ))}
                                    <div ref={messagesEndRef} className="mb-4" /> {/* Scroll to here */}

                </div>

                <div className="message-typing-block">
                  <div className="input-box">
                    <input
                      type="text"
                      className="msg-type"
                      placeholder="Type…"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />

<input
                      type="file"
                      id="fileUpload"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <Button
                      className="file-attach"
                      onClick={() => document.getElementById("fileUpload").click()}
                    >
                      <img src={Clip} alt="clip" />
                    </Button>
                  
                    {image && (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        style={{ maxWidth: "50px", maxHeight: "50px" }}
                      />
                    )}
                  </div>
                  <div className="sent-btn">
                    <Button
                      className="sent"
                      onClick={(e) => {
                        e.preventDefault();
                        if (message?.trim() !== "" || image != null) {
                          sendMessage();
                        }
                      }}
                      disabled={sendingMessage} // Disable button while sending
                    >
                                            {sendingMessage ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <img src={Sent} alt="sent" />
                      )}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="start-chat">
                <p>Select a user to start chatting</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
