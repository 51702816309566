import React, { useState } from 'react'
import '../../assets/css/login.css';
import doctorsImg from '../../assets/images/Group 3038.svg';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';
import LoginPage from '../LoginPage/Login';
import { Col, Row } from 'react-bootstrap';
import include from "../../assets/images/include.png";


const Home = () => {
    const navigate = useNavigate();
    const [mobile,setMobile]= useState(false)

  
    return (
        <div className='login-main'>
        <div className="content-wrapper">
        <div className="banner-part">
          <div>
            <div
              className="container"
              style={{
                paddingLeft: "5rem",
                paddingRight: "5rem",
                marginBottom: "4rem",
              }}
            >
              <img
                src="https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg"
                width={200}
              />
            </div>
            <div
              className="container text-start "
              style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
            >
              <h3
                style={{
                  color: "#ff6f48",
                  fontWeight: "300",
                }}
              >
                {" "}
                <span style={{whiteSpace:"nowrap"}}>Digital Home Exercise Prescriptions, </span>
                <br />
                <span style={{fontWeight: "700"}}>Always Free! </span>
              </h3>
              <p
                className=""
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                What we included ?
              </p>
              <section
                className="include-section"
                style={{ paddingBottom: "1rem" }}
              >
                <div className="">
                  <div
                    className="include-allcontent-section"
                    style={{ marginLeft: "0px" }}
                  >
                    <Row className="justify-content-center">
                      <Col md={12} sm={12}>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Mobile app for patient and physical therapist
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Browse a library of over 1,600+ exercise
                          </p>
                        </div>
                      </Col>
                      <Col md={12} sm={12}>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Access more then 250+ protocols
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Assign workout Digitally in to Mobile app , also can
                            print and email
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Seamless workout & patient management{" "}
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                            Many more features are waiting for you
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
           
            </div>

            <div className="container" style={{ paddingLeft: "5rem", paddingRight: "5rem",marginTop:"2rem"}} >

<span style={{fontSize:"11px"}}>
 Copyright  ©  2024 VirtueLife. All rights reserved.
    </span>

    <div style={{fontSize:"10px"}}>
        <span> 
        <a
  href="https://virtuelife.ai/terms/"
  target="_blank"
  rel="noreferrer"
>
  Terms of Use
</a>
 </span> &nbsp;   | &nbsp;
        <span>  <a
  href="https://virtuelife.ai/privacy/"
  target="_blank"
  rel="noreferrer"
>
  Privacy Policy
</a> </span>

      </div>
</div>
          </div>
        </div>
          <div className="form-part">

             

          {mobile === true ?  <Login mobile={mobile} setMobile={setMobile}/> : <LoginPage  setMobile={setMobile}/>}
          </div>
          </div>
          </div>
    )
}

export default Home
