import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/login.css';
import doctorsImg from '../../assets/images/Group 3038.svg';
import { AiOutlineClose } from 'react-icons/ai';
import IndiaFlag from '../../assets/images/country-flag.svg';
import { BiSolidDownArrow } from 'react-icons/bi';
import { Modal, Badge } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import TruecalloerLogo from '../../assets/images/Truecaller_Logo.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Auth from '../../libs/auth';
import { post } from '../../libs/http-hydrate';
import firebase from "../../common/firebase";
import FieldValidationError from '../../common/FieldValidation';
import logo from '../../assets/images/RecureMe.svg'
import { isValidPhoneNumber } from "libphonenumber-js";
import doneImg from '../../assets/images/Opps.svg'; // Import the image

const Login = ({mobile,setMobile}) => {
  const navigate = useNavigate();
  const [name, setName] = useState(""); // Add this line for the new name state
  const nameInputRef = useRef(null);
  const [error, seterror] = useState({
    name: false,
  });
  const [show, setShowModal] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [selected, setSelected] = useState("IN");
  const [isLoading, setLoading] = useState(false); // Add a loading state
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  const [timerSeconds, setTimerSeconds] = useState(15 * 60); // 30 minutes in seconds
  const [isTimerRunning, setTimerRunning] = useState(false);
  const otpInputRefs = useRef([]); 
  const [verificationId, setVerificationId] = useState('');
  const otpInputsRef = useRef([]);
  const [otperrorMessage, setOTPErrorMessage] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const closeModal = () => setModalShow(false);

  const [loginData, setLoginData] = useState({
    mobile_number: "",
    name:""
  });

  const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);

  // Your existing code...

  // Function to handle changes in OTP input fields
  const handleOtpInputChange = (index, value) => {
      // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
      const newValue = /^\d$/.test(value) ? value : '';
      setOtpDigits((prevDigits) => {
        const newDigits = [...prevDigits];
        newDigits[index] = newValue;
        return newDigits;
      });
  
      // Move to the next input field if a digit is entered
      if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
        otpInputRefs.current[index + 1].focus();
      }
      setOTPErrorMessage("")

  };

  // Function to check if all fields have data and call verifyOTP function
  const handleVerifyOTPs = () => {
    const isAllFieldsFilled = otpDigits.every(digit => digit !== '');
    if (isAllFieldsFilled) {
      const otp = otpDigits.join(''); // Convert the array of digits to a single OTP string
      handleVerifyOTP(otp); // Call your verifyOTP function with the OTP as a parameter
    } else {
      // Handle the case when all fields are not filled
      // Maybe show an error message or take appropriate action
      setOTPErrorMessage("Please Add Correct OTP")
    }
  };
  // Function to handle backspace key for removing the digits
  const handleOtpInputKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otpDigits[index] === '') {
      // Move focus to the previous input field when backspace is pressed and the current field is empty
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const newOtpDigits = [...otpDigits];
        newOtpDigits[prevIndex] = '';
        setOtpDigits(newOtpDigits);
        const prevInput = document.getElementById(`otp-input-${prevIndex}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  }


  const submitOTP = () => {
    navigate('/profile');
  }

  useEffect(() => {
    if (Auth?.getCurrentUser()) {
      navigate("/dashboard")
    }
  }, [])


  const resetTimer = () => {
    setTimerSeconds(15 * 60); // Reset timer to 15 minutes
    setTimerRunning(true); // Start the timer
  };

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");
      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  
  const handleSendOTP = () => {

    if (!loginData?.mobile_number) {
      setMobileNumberError("Please provide a mobile number");
      return false;
    } else {
      setMobileNumberError(""); // Clear any existing error message
      // ... rest of the function logic
    }

    // Set loading to true when starting the API call
    setLoading(true);

    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
    });

    firebase
      .auth()
      .signInWithPhoneNumber("+" + loginData?.mobile_number, recaptchaVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setModalShow(true);
        setTimerRunning(true);

      })
      .catch((error) => {
        setShowModal(true)
      }).finally(() => {
        setLoading(false); // Set loading to false after API call completion
      });

      resetTimer();

  };


  const handleVerifyOTP = (otp) => {
    // Set loading to true when starting the API call
    setLoadingVer(true);

    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((result) => {
        if (result) {
          verifyOTP(verificationId, result?.user?.uid)
        } else {

        }
      })
      .catch((error) => {
        setOtpDigits(['', '', '', '', '', '']); // Reset OTP digits
        setOTPErrorMessage("Please Enter valid otp")
        setLoadingVer(false); // Set loading to false after API call completion
        if (otpInputRefs.current[0]) {
          otpInputRefs.current[0].focus();
        }
      })
  };


  async function verifyOTP(verificationId, userID) {
    const formdata = new FormData();
    formdata.append("type", "physiotherapist");
    formdata.append("mobileNumber", "+" + loginData?.mobile_number);
    formdata.append("name",name);
    formdata.append("action","login")
    formdata.append("device", "Web");
    try {
      const response = await post("/getToken", formdata);
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: response?.data?.physio?.email,
            mobile_number: response?.data?.physio?.mobile,
            token: response?.data?.physio?.token,
            profile_image:response?.data?.physio?.profile_image,
            _id:response?.data?.physio?._id,
            profileCompleted:response?.data?.physio?.profileCompleted,
            gender:response?.data?.physio?.gender,
            name: response?.data?.physio?.name
          })
        );

        // if (response?.data?.is_verified === false) {
        //   navigate("/profileData")
        // }

        // else {
          navigate("/dashboard")
        // }


      } else {

      }
    } catch (error) {

      toast.error(error?.response?.message)
    }
  };

  useEffect(() => {
    let interval;
  
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimerSeconds(prevSeconds => {
          if (prevSeconds === 0) {
            // Timer has reached 0, do something here
            setTimerRunning(false);
            // Maybe close the modal or take any necessary action
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
  
    return () => clearInterval(interval); // Clear the interval when the component unmounts
  
  }, [isTimerRunning]);
  
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain').trim(); // Get the pasted text
  
    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split('');
  
    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpInputChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ''; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    }
    else if (e.key === 'Enter') {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every(digit => digit !== '');
      if (isAllFieldsFilled) {
        handleVerifyOTPs();
      } else {
        // Handle the case when all fields are not filled
        setOTPErrorMessage("Please fill all the OTP digits");
      }
    }
  };
  function onHide(){
    setShowModal(false)
    window.location.reload();
  }


  async function checkMobileNumber() {


    const isPhoneProvided = loginData?.mobile_number?.trim() != "";

    const isPhoneValid =
    isPhoneProvided &&
    isValidPhoneNumber(
      remove_first_occurrence(
        loginData?.mobile_number,
        loginData?.countryCode
      ),
      loginData?.country?.toUpperCase()
    );


    if (!isPhoneValid) {
      setMobileNumberError("Please provide a valid mobile number");
      return false;
    } else {
      setMobileNumberError(""); // Clear any existing error message
      // ... rest of the function logic
    }
    const formdata = new FormData();
    formdata.append("type", "physiotherapist");
    formdata.append("mobileNumber", "+" + loginData?.mobile_number);
    formdata.append("action","login")
    try {
      setLoading(true);
      const response = await post("/checkMobileNumber", formdata);
      if (response?.status === 200) {
        handleSendOTP()
      }
    } catch (error) {
      setLoading(false);
      seterror((p) =>({...p,mobileError:true,mobileErrorStatement:error?.response?.data?.err}))
    }
  };
  function remove_first_occurrence(str, searchstr) {
    var index = str.indexOf(searchstr);
    if (index === -1) {
      return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
  }
  return (
    <>


      <ToastContainer />
      <div id="recaptcha-container"></div>
   
            {/* <button className='close-btn'>
              <AiOutlineClose size={28} />
            </button> */}
            <div className="content">
            <div className='w-100 text-center mobile-logo'>
    <img src={logo} alt="docpy-logo" width={180} />
  </div>
              <div className="header-part text-center gap-0">

                <h3>Welcome!</h3>
                <p>Sign in to your account.</p>
                <div className='d-flex justify-content-center mt-3'>
                <div className='px-2'>
  <label style={{color:"#f36f49",cursor:"pointer"}}>
    <input
      type="radio"
      name="loginOption"
      value="email"
      checked={!mobile}
      onChange={() => setMobile(false)}
    />
    &nbsp; Email ?  
  </label>
</div>
<div className='px-2'>
  <label style={{color:"#f36f49",cursor:"pointer"}}>
    <input
      type="radio"
      name="loginOptionMo"
      value="email"
      checked={mobile}
      onChange={() => setMobile(true)}
      style={{ color: "#23BEE3" }}

    />
    &nbsp; Mobile ?
  </label>
</div></div>
              </div>
              <div className="middle-form">
             
                <div className="w-100">


                  <PhoneInput placeholder="Enter Your Mobile Number" country={'in'}
                onChange={(phone, country) => {
                  setLoginData((p) => ({
                    ...p,
                    countryCode: country?.dialCode,
                  }));
                  setLoginData((p) => ({
                    ...p,
                    country: country?.countryCode,
                  }));
                  setLoginData((p) => ({ ...p, mobile_number: phone }));
                  setMobileNumberError(""); // Clear the error message when the user starts typing
                  seterror((prevErrors) => ({
                    ...prevErrors,
                    mobileError: false,
                    mobileErrorStatement:""
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent default Enter behavior
                    checkMobileNumber(); // Manually trigger form submission logic
                  }
                }}
                />
  {mobileNumberError && <FieldValidationError message={mobileNumberError} />}

                </div>
                {error?.mobileError && error?.mobileErrorStatement && <FieldValidationError message={error?.mobileErrorStatement} />}

                <p className='info-text'>By signing up you are agree to our <a href="https://virtuelife.ai/terms/" target='_blank' rel="noreferrer">Terms of Use</a> and <a href="https://virtuelife.ai/privacy/" target='_blank' rel="noreferrer">Privacy Policy</a></p>
              </div>
              <div className="action-part">
                <button className="btn btn-orange"
                type='submit'
                disabled={isLoading}
                onClick={() => { checkMobileNumber() }}>
                  {isLoading ? "Sending..." : "Login"}              </button>
         

<div className="text-center w-100 mt-3">   
              <p className="text-secondary m-0">
              No account? Join now!
              </p>

              <p
                className="info-text"
                style={{
                  color: "#ff6f48",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signup",{state:{email:"true"}})
                  // sendOtp();
                }}
              >
               Create an account  
              </p>
              </div>
{/* 
                <button onClick={(e) => {
                  e.preventDefault();
                  navigate("/signup",{state:{mobile:"true"}})
                }} className="btn btn-orange outline" >
                  Signup with Mobile 
                </button> */}


                {/* <button onClick={(e) => {
                  e.preventDefault();
                  navigate("/signup",{state:{email:"true"}})
                }} className="btn btn-orange outline" >
                  Signup 
                </button> */}



              </div>
            </div>
         
            <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      <Modal.Header>
        <div className="top-heading w-100 d-flex justify-content-end text-end">
          <button className="btn btn-transpatent" onClick={onHide}>
            X
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p>Opps!</p>
            <span className='text-secondary'>  If you're experiencing issues with the website, please try refreshing your browser.
</span>
  
            <button
              className="theme-button btn btn-primary mt-3"
              style={{ minWidth: "300px", padding: "14px" }}
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Body style={{ border: 'none' }}>
            <div className="content-modal">
              <div className="top-btn-part">
                <button className='btn close-btn' onClick={closeModal}>
                  <AiOutlineClose size={20} color='#000' />
                </button>
              </div>
              <div className="modal-inside-content">
                <h5 className='heading'>Authentication Code Sent to your number</h5>
                <p className='desc-text'>6 digit Authentication Code has sent to your mobile number</p>
                <p className='mobileno'>{"+" + loginData?.mobile_number}
                 {/* <Badge pill className='edit-badge' onClick={(e) => {
                  e.preventDefault();
                  setModalShow(false)
                  window.location.reload()
                }}>Edit</Badge> */}
                </p>
                <div className="otp-input-group" onPaste={handlePaste}>
                  {otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleOtpInputChange(index, e.target.value)}
                      onKeyDown={(e) => handleBackspace(index, e)}
                      autoFocus={index === 0}
                      ref={(el) => otpInputRefs.current[index] = el} // Save input element references to the ref array
                    />
                  ))}

                </div>
                {otperrorMessage && <FieldValidationError message={otperrorMessage} /> }
                <button className='btn-continue' disabled={isLoadingVer} onClick={handleVerifyOTPs}>{isLoadingVer ? "Verifying OTP" : "Continue"}</button>
                <span className='notreceive-text'>Haven't you received the SMS?</span>
                <button className='resend-btn'   onClick={(e) => {
    e.preventDefault();
    handleSendOTP();
    resetTimer(); // Call resetTimer to restart the 15-minute countdown
  }}
  disabled={timerSeconds !== 0} // Disable button until timer reaches 0
  style={{
    // Prevents hover and click
    cursor : timerSeconds !== 0 ? 'not-allowed' : 'pointer',
  }}
>Resend Code</button>
<p className="timer">
  {timerSeconds > 0
    ? `${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
        .toString()
        .padStart(2, '0')}`
    : "You can now resend the code"}
</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  )
}

export default Login